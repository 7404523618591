import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { is } from "bpmn-js/lib/util/ModelUtil";

/**
 * properties to hold information about the AV contract
 * only available for privacy:Processor
 * modeled as attribute of privacy:Processor
 */
export default function(group, element) {
    if (is(element, "privacy:Processor")) {
        group.entries.push(entryFactory.textField({
            id: "pathToAV",
            label: "Path to Processor Contract",
            modelProperty: "pathToAV"
        }));
    }
}
