import { is } from "bpmn-js/lib/util/ModelUtil";

import { forEach } from "min-dash"

import { getProperties } from "./PropertyHelpers";

/**
 * public function to return the ExtensionElements of a business object
 */
export function getExtensionElement(type, businessObject) {
    const extensionElements = getExtensionElements(type, businessObject);

    if (extensionElements) {
        return extensionElements[0];
    }

    return false;
}

/**
 * internal function to return the ExtensionElements of a business object
 */
function getExtensionElements(type, businessObject) {
    if (businessObject && businessObject.get('extensionElements') && businessObject.get('extensionElements').values && businessObject.get('extensionElements').values.length > 0) {
        const extensionElements = businessObject.get('extensionElements').values;

        const resultingElements = extensionElements.filter(function (element) {
            return is(element, type);
        });

        if (resultingElements.length > 0) {
            return resultingElements;
        }
    }
    return false;
}

/**
 * public function to handle ExtensionElements of business object
 */
export function createOrReplaceElement(moddle, businessObject, type, elementToAdd) {
    let extensionElements = businessObject.extensionElements;
    if (extensionElements && extensionElements.values) {
        let key = getKeyByExtensionElement(type, businessObject);
        if (key !== false) {
            businessObject.extensionElements.values[key] = elementToAdd;
        } else {
            extensionElements.values.push(elementToAdd);
        }
    } else {
        extensionElements = moddle.create('bpmn:ExtensionElements', {
            values: [elementToAdd]
        });
    }

    return extensionElements;
}

/**
 * helper function to determine key for ExtensionElement
 */
function getKeyByExtensionElement(type, businessObject) {
    let key = false;

    forEach(businessObject.extensionElements.values, function (extensionElement, currentKey) {
        if (is(extensionElement, type)) {
            key = currentKey;
        }
    });

    return key;
}

/**
 * public function to delete ExtensionElements from business object
 */
export function deleteProperty(extensionElements, type, propertyName) {
    let keyOfExtensionElement = 0;
    forEach(extensionElements.values, function (extensionElement, key) {
        if (is(extensionElement, type)) {
            keyOfExtensionElement = key;
            delete extensionElements.values[key][propertyName];
        }
    });

    const metamodelProps = getProperties(type);
    let isElementEmpty = true;

    // check if extensionelements is empty / there are no other properties populated for the given element...
    forEach(metamodelProps, function (prop) {
        const suffix = (prop.name || '').replace(/^[^:]*:/g, '');
        if (typeof extensionElements.values[keyOfExtensionElement] !== "undefined" && typeof extensionElements.values[keyOfExtensionElement][suffix] !== "undefined") {
            isElementEmpty = false;
        }
    });

    if (isElementEmpty) {
        return false;
    }

    return extensionElements;
}
