import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";

import {
    getEnumValues,
    getValueHelper,
    setValueHelper
} from "../utilities/PropertyHelpers";

/**
 * properties to hold the information for legal basis
 * selectBox is based on enum of privacy:LegalParagraph
 * only available for process or participant
 * models the properties as ExtensionElements
 */
export default function(group, element, moddle, type) {
    if (isAny(element, ['bpmn:Process', "bpmn:Participant"])) {

        const setValue = function(businessObject, property) {
            return setValueHelper(businessObject, type, property, moddle);
        };

        const getValue = function(businessObject, property) {
            return getValueHelper(businessObject, type, property);
        };

        group.entries.push(entryFactory.selectBox({
            id: 'legalParagraph',
            description: 'Legal basis for the process',
            label: 'Legal Paragraph',
            modelProperty: 'legalParagraph',
            selectOptions: getEnumValues('privacy:LegalParagraph'),
            set: setValue(getBusinessObject(element), "legalParagraph"),
            get: getValue(getBusinessObject(element), "legalParagraph")
        }));
    }
}
