import inherits from 'inherits';

import ReplaceMenuProvider from 'bpmn-js/lib/features/popup-menu/ReplaceMenuProvider';

import { is } from "bpmn-js/lib/util/ModelUtil";

import { isDifferentType } from "bpmn-js/lib/features/popup-menu/util/TypeUtil";

import { filter } from "min-dash";

import replaceOptions from "./utilities/PopupReplaceOptions";

/**
 * This module implements the defined replacement options
 */
export default function CustomPopupMenuProvider(popupMenu, modeling, moddle, bpmnReplace, rules, translate) {
    ReplaceMenuProvider.call(this, popupMenu, modeling, moddle, bpmnReplace, rules, translate);
}

inherits(CustomPopupMenuProvider, ReplaceMenuProvider);

CustomPopupMenuProvider.$inject = ['popupMenu', 'modeling', 'moddle', 'bpmnReplace', 'rules', 'translate'];

const cachedGetEntries = ReplaceMenuProvider.prototype.getEntries;

CustomPopupMenuProvider.prototype.getEntries = function (element) {
    const businessObject = element.businessObject;
    let entries;

    const differentType = isDifferentType(element);

    // flow nodes
    if (is(businessObject, 'bpmn:Task')) {
        entries = filter(replaceOptions.TASK, differentType);
        return this._createEntries(element, entries);
    } else if (is(businessObject, 'bpmn:Gateway')) {
        entries = filter(replaceOptions.GATEWAY, differentType);
        return this._createEntries(element, entries);
    } else if (is(businessObject, 'privacy:PersonalData')) {
        entries = filter(replaceOptions.DATA, differentType);
        return this._createEntries(element, entries);
    } else {
        return cachedGetEntries.call(this, element);
    }
};
