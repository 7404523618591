import inherits from 'inherits';

import { is } from "bpmn-js/lib/util/ModelUtil";

import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";

import { reduce } from "min-dash";

import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';

const HIGH_PRIORITY = 1500;

/**
 * This module specifies the rules for the custom elements.
 * Here, all connections are defined and it is regulated where elements can be created
 */
export default function CustomRules(eventBus) {
    RuleProvider.call(this, eventBus);
}

inherits(CustomRules, RuleProvider);

CustomRules.$inject = [ 'eventBus' ];


CustomRules.prototype.init = function() {

    function canCreate(shape, target) {

        if (!isPrivacyElement(shape)) {
            return;
        }

        // allow creation of privacy:PersonalData only in privacy:DataCategory
        if (is(shape, 'privacy:PersonalData')) {
            return is(target, 'privacy:DataCategory');
        }

        // allow creation on processes
        return is(target, 'bpmn:Process') || is(target, 'bpmn:Participant') || is(target, 'bpmn:Collaboration');
    }

    function canConnect(source, target) {
        // connect bpmn:Task and privacy:PersonalData with an DataConnection
        if ((is(source, 'privacy:PersonalData') && is(target, 'bpmn:Activity')) || (is(target, 'privacy:PersonalData') && is(source, 'bpmn:Activity'))) {
            return { type: 'privacy:DataConnection' };
        }

        // connect privacy:DataCategory and privacy:Database with an DataConnection
        if ((is(source, 'privacy:Database') && is(target, 'privacy:DataCategory')) || (is(target, 'privacy:Database') && is(source, 'bpmn:DataCategory'))) {
            return { type: 'privacy:DataConnection' };
        }

        // connect privacy:DataSubject and privacy:DataCategory with an Association
        if ((is(source, 'privacy:DataSubject') && is(target, 'privacy:DataCategory')) || (is(target, 'privacy:DataSubject') && is(source, 'bpmn:DataCategory'))) {
            return { type: 'privacy:DataConnection' };
        }

        // connect bpmn:Task and privacy:PersonalData with an Association
        if ((is(source, 'privacy:DataSource') && is(target, 'privacy:DataCategory')) || (is(target, 'privacy:DataSource') && is(source, 'privacy:DataCategory'))) {
            return { type: 'privacy:DataConnection' };
        }

        // connect privacy:ThirdPartyProcessing with the third party
        if ((is(source, 'privacy:ThirdPartyProcessing') && is(target, 'bpmn:Participant'))) {
            return { type: "privacy:ThirdPartyConnection" };
        }

        // don't allow connection bpmn:Task and privacy:DataSource
        if ((is(source, 'privacy:DataSource') && is(target, 'bpmn:Task')) || (is(target, 'privacy:DataSource') && is(source, 'bpmn:Task'))) {
            return false;
        }

        // don't allow connection bpmn:Task and privacy:DataCategory
        if ((is(source, 'privacy:DataCategory') && is(target, 'bpmn:Task')) || (is(target, 'privacy:DataCategory') && is(source, 'bpmn:Task'))) {
            return false;
        }

        // don't allow to connect custom elements with themselves
        if (isAny(source, ['privacy:DataSubject', 'privacy:DataSource', 'privacy:DataCategory', 'privacy:Database', 'privacy:PersonalData'])) {
            if (source === target) {
                return false;
            }
        }

        // don't allow connection privacy:DataSubject and privacy:DataSource
        if ((is(source, 'privacy:DataSubject') && is(target, 'privacy:DataSource')) || (is(target, 'privacy:DataSubject') && is(source, 'privacy:DataSource'))) {
            return false;
        }

        // don't allow connection bpmn:Task and privacy:Database
        if ((is(source, 'privacy:Database') && is(target, 'bpmn:Task')) || (is(target, 'privacy:Database') && is(source, 'bpmn:Task'))) {
            return false;
        }


        // don't allow connection bpmn:Event and privacy:Database
        if ((is(source, 'privacy:Database') && is(target, 'bpmn:Event')) || (is(target, 'privacy:Database') && is(source, 'bpmn:Event'))) {
            return false;
        }
    }

    this.addRule('elements.move', HIGH_PRIORITY, function (context) {
        var target = context.target,
            shapes = context.shapes;

        var type;

        // do not allow mixed movements of custom / BPMN shapes
        // if any shape cannot be moved, the group cannot be moved, too
        var allowed = reduce(shapes, function (result, s) {
            if (type === undefined) {
                type = isPrivacyElement(s);
            }

            if (type !== isPrivacyElement(s) || result === false) {
                return false;
            }

            return canCreate(s, target);
        }, undefined);

        // reject, if we have at least one
        // custom element that cannot be moved
        return allowed;
    });

    this.addRule('shape.create', HIGH_PRIORITY, function (context) {
        var target = context.target,
            shape = context.shape;

        // ContainerElements should be expanded
        if (is(shape, 'bpmn:FlowElementsContainer')) {
            shape.isExpanded = true;
            shape.businessObject.di.isExpanded = true;
        }

        return canCreate(shape, target);
    });


    this.addRule('connection.create', HIGH_PRIORITY, function (context) {

        var source = context.source,
            target = context.target;

        return canConnect(source, target);
    });

    this.addRule('connection.reconnectStart', HIGH_PRIORITY, function (context) {
        var connection = context.connection,
            source = context.hover || context.source,
            target = connection.target;

        return canConnect(source, target);
    });

    this.addRule('connection.reconnectEnd', HIGH_PRIORITY, function (context) {
        var connection = context.connection,
            source = connection.source,
            target = context.hover || context.target;

        return canConnect(source, target);
    });

    // enable resizing
    this.addRule('shape.resize', 1500, function (context) {

        var element = context.shape,
            bo = element.businessObject;

        if (isAny(bo, ['privacy:AbstractContainerElement'])) {
            return true;
        }

        if (is(bo, 'privacy:PersonalData')) {
            return true;
        }

        if (is(bo, 'bpmn:Task')) {
            return true;
        }
    });
};

function isPrivacyElement(element) {
    return element && /^privacy:/.test(element.type);
}
