import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import {
    getBusinessObject,
    is
} from "bpmn-js/lib/util/ModelUtil";

import {
    getEnumValues,
    getValueHelper,
    setValueHelper
} from "../utilities/PropertyHelpers";

/**
 * properties to hold all information about the personal data retention period
 * only available for privacy:PersonalData
 * models the properties as ExtensionElements
 */
export default function(group, element, moddle, type) {
    if (is(element, "privacy:PersonalData")) {

        const setValue = function(businessObject, property) {
            return setValueHelper(businessObject, type, property, moddle);
        };

        const getValue = function (businessObject, property) {
            return getValueHelper(businessObject, type, property);
        };

        group.entries.push(entryFactory.textField( {
            id: "duration",
            label: "Retention Duration",
            modelProperty: "duration",
            set: setValue(getBusinessObject(element), "duration"),
            get: getValue(getBusinessObject(element), "duration")
        }));

        group.entries.push(entryFactory.selectBox({
            id: "unit",
            label: "Unit of duration",
            modelProperty: "unit",
            selectOptions: getEnumValues("privacy:Unit"),
            set: setValue(getBusinessObject(element), "unit"),
            get: getValue(getBusinessObject(element), "unit")
        }));

        group.entries.push(entryFactory.textBox( {
            id: "legalBasis",
            label: "Legal Basis for Duration",
            modelProperty: "legalBasis",
            set: setValue(getBusinessObject(element), "legalBasis"),
            get: getValue(getBusinessObject(element), "legalBasis")
        }));

        group.entries.push(entryFactory.textBox( {
            id: "comment",
            label: "Comment",
            modelProperty: "comment",
            set: setValue(getBusinessObject(element), "comment"),
            get: getValue(getBusinessObject(element), "comment")
        }));
    }
}
