// https://github.com/bpmn-io/bpmn-js-example-custom-shapes/blob/master/app/custom-modeler/custom/index.js

import CustomContextPadProvider from './CustomContextPadProvider';
import CustomPalette from './CustomPalette';
import CustomRenderer from './CustomRenderer';
import CustomRules from './CustomRules';
import CustomPopupMenuProvider from './CustomPopupMenuProvider';
import CustomPropertiesProvider from './CustomPropertiesProvider';

/**
 * This module ties all the custom modules together and makes it available for the custom modeler
 */
export default {
    __init__: [
        'contextPadProvider',
        'customRenderer',
        'customRules',
        'customPopupMenuProvider',
        'paletteProvider',
        'propertiesProvider'
    ],
    contextPadProvider: [ 'type', CustomContextPadProvider ],
    customRenderer: [ 'type', CustomRenderer ],
    customRules: [ 'type', CustomRules ],
    customPopupMenuProvider: [ 'type', CustomPopupMenuProvider ],
    paletteProvider: [ 'type', CustomPalette ],
    propertiesProvider: [ 'type', CustomPropertiesProvider]
};
