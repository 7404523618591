import meta from '../../privacy';

import { forEach } from "min-dash";

import {
    createOrReplaceElement,
    deleteProperty,
    getExtensionElement
} from "./ExtensionElementUtil";

import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import cmdHelper from "bpmn-js-properties-panel/lib/helper/CmdHelper";

/**
 * Read literal values of passed enumType from meta model
 */
export function getEnumValues(enumType) {
    const type = getTypeWithoutNS(enumType);

    const enums = meta.enumerations.filter(function(enumElement) {
        return enumElement.name === type;
    });

    if (enums.length > 0) {
        return enums[0].literalValues;
    }
}

/**
 * Delete Namespace of given Element
 */
function getTypeWithoutNS(elementType) {
    return (elementType || '').replace(/^[^:]*:/g, '');
}

export function getProperties(elementType) {
    let props = false;
    if (meta.types) {
        const elementSuffix = getTypeWithoutNS(elementType);
        forEach(meta.types, function(type) {
            if (type.name === elementSuffix) {
                props = type["properties"];
            }
        });

    }

    return props;
}

/**
 * Generic function used to append passed property as Extension element to businessObject
 */
export function getValueHelper(businessObject, type, property) {
    return function (element) {
        let attrs = {};

        const customElement = getExtensionElement(type, getBusinessObject(element));
        attrs[property] = customElement[property];

        return attrs;
    };
}

/**
 * Generic function used to append passed property as Extension element to businessObject
 */
export function setValueHelper(businessObject, type, property, moddle) {
    return function(element, values) {

        // only allow numbers as input for duration
        if (type === "privacy:Retention" && property === "duration") {
            if (isNaN(values.duration)) {
                alert("Numbers only");
                return false;
            }
        }

        let extensionElements = [];

        if (typeof values[property] !== "undefined" && values[property] !== "") {
            let attrs = {};
            attrs[property] = values[property];

            let customElement = getExtensionElement(type, businessObject);

            if (customElement) {
                customElement[property] = attrs [property];
            } else {
                customElement = moddle.create(type, attrs);
            }

            extensionElements = createOrReplaceElement(moddle, businessObject, type, customElement);
        } else {
            extensionElements = deleteProperty(businessObject.extensionElements, type, property);

            if (extensionElements === false) {
                delete businessObject.extensionElements;
                return cmdHelper.updateProperties(element, businessObject, {});
            }
        }

        return cmdHelper.updateProperties(element, {"extensionElements": extensionElements});
    }
}
