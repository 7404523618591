import inherits from 'inherits';

import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider';

import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import {
    assign,
    bind
} from "min-dash";

/**
 * this class handles the context pad which is displayed when selecting an element
 * basically all options for the custom elements are removed
 * only the connect-tool is available
 */
export default function CustomContextPadProvider(injector, connect, popupMenu, canvas, contextPad) {

    injector.invoke(ContextPadProvider, this);

    const cached = bind(this.getContextPadEntries, this);

    function startConnect(event, element, autoActivate) {
        connect.start(event, element, autoActivate);
    }

    this.getContextPadEntries = function(element) {
        let actions = cached(element);

        if (isPrivacyElement(element)) {
            const businessObject = element.businessObject;
            let newActions = {};

            // delete
            if (isAny(businessObject, ['privacy:PersonalData', 'privacy:Database', 'privacy:DataCategory', 'privacy:DataSource', 'privacy:DataSubject'])) {
                const cachedActions = actions;
                newActions = {
                    delete: cachedActions.delete
                };

                if (isAny(businessObject, [])) {
                    newActions.replace = cachedActions.replace
                }

                actions = newActions;
            }

            // connect
            if (isAny(businessObject, ['privacy:PersonalData', 'privacy:Database', 'privacy:DataSource', 'privacy:DataSubject', 'privacy:DataCategory'])) {

                assign(actions, {
                    'connect': {
                        group: 'connect',
                        className: 'bpmn-icon-connection-multi',
                        title: 'Connect using custom connection',
                        action: {
                            click: startConnect,
                            dragstart: startConnect
                        }

                    }
                });
            }

            if (isAny(businessObject, ['privacy:PersonalData', 'privacy:SpecialPersonalData'])) {
               assign(actions, {
                   'replace': {
                       group: 'edit',
                       className: 'bpmn-icon-screw-wrench',
                       title: 'Change type',
                       action: {
                           click: function(event, element) {

                               var position = assign(getReplaceMenuPosition(element), {
                                   cursor: { x: event.x, y: event.y }
                               });

                               popupMenu.open(element, 'bpmn-replace', position);
                           }
                       }
                   }
               });
            }
        }
        return actions;
    };

    function getReplaceMenuPosition(element) {

        var Y_OFFSET = 5;

        var diagramContainer = canvas.getContainer(),
            pad = contextPad.getPad(element).html;

        var diagramRect = diagramContainer.getBoundingClientRect(),
            padRect = pad.getBoundingClientRect();

        var top = padRect.top - diagramRect.top;
        var left = padRect.left - diagramRect.left;

        var pos = {
            x: left,
            y: top + padRect.height + Y_OFFSET
        };

        return pos;
    }
}

inherits(CustomContextPadProvider, ContextPadProvider);

CustomContextPadProvider.$inject = [
    'injector',
    'connect',
    'popupMenu',
    'canvas',
    'contextPad'
];

function isPrivacyElement(element) {
    return element && /^privacy:/.test(element.type);
}
