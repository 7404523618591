import Modeler from 'bpmn-js/lib/Modeler';

import inherits from 'inherits';

import CustomModule from './modules';

/**
 * This module connects all custom modules and publishes the custom modeler which can be used by the application.
 */
export default function CustomModeler(options) {
    Modeler.call(this, options);

    this._customElements = [];
}

inherits(CustomModeler, Modeler);

CustomModeler.prototype._modules = [].concat(
    CustomModeler.prototype._modules,
    [
        CustomModule
    ]
);
