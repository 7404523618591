import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
    assign,
    forEach,
    isObject
} from 'min-dash';

import { createLine } from "diagram-js/lib/util/RenderUtil";

import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";

import {
    append as svgAppend,
    attr as svgAttr,
    classes as svgClasses,
    create as svgCreate,
    innerSVG
} from 'tiny-svg'

import inherits from "inherits";

import Icons from "../../icons";

const TASK_BORDER_RADIUS = 2;

/**
 * A renderer that knows how to render the custom elements and takes care of labelling etc.
 */
export default function CustomRenderer(eventBus, styles, pathMap, modeling, textRenderer) {
    BaseRenderer.call(this, eventBus, 1500);

    var computeStyle = styles.computeStyle;

    const handlers = this.handlers = {
        'label': function(parentGfx, element) {
            var textElement = renderExternalLabel(parentGfx, element);
            var textBBox;

            try {
                textBBox = textElement.getBBox();
            } catch (e) {
                textBBox = {width: 0, height: 0, x: 0};
            }

            element.x = Math.ceil(element.x + element.width / 2) - Math.ceil(textBBox.width / 2);

            element.width = Math.ceil(textBBox.width);
            element.height = Math.ceil(textBBox.height);

            svgAttr(textElement, {
                transform: 'translate(' + (-1 * textBBox.x) + ',0)'
            });

            return textElement;
        },
        'privacy:Task': function (parentGfx, element, attrs) {
            attrs = assign(attrs || {}, {
                fillOpacity: 1,
                stroke: '#376092'
            });

            const rect = drawRect(parentGfx, element.width, element.height, TASK_BORDER_RADIUS, attrs);
            renderEmbeddedLabel(parentGfx, element, 'center-middle');
            attachTaskMarkers(parentGfx, element);
            return rect;
        },
        'privacy:DataProcessing': function(parent, element) {
            const task = renderer('privacy:Task')(parent, element);

            createCustomIcon(Icons.dataprocessingPng, parent);

            return task;
        },
        'privacy:AutomaticProcessing': function(parent, element) {
            const task = renderer('privacy:Task')(parent, element);

            createCustomIcon(Icons.automaticPng, parent);

            return task;
        },
        'privacy:Processor': function(parent, element) {
            const task = renderer('privacy:Task')(parent, element);

            createCustomIcon(Icons.processorPng, parent);

            return task;
        },
        'privacy:JointController': function(parent, element) {
            const task = renderer('privacy:Task')(parent, element);

            createCustomIcon(Icons.jointPng, parent);

            return task;
        },
        'privacy:InstructionlessProcessing': function(parent, element) {
            const task = renderer('privacy:Task')(parent, element);

            createCustomIcon(Icons.instructionlessPng, parent);

            return task;
        },
        'privacy:DocumentObject': function(parentGfx, element) {
            if (element.width < 50) {
                element.width = 50;
            }
            if (element.height < 75) {
                element.height = 75;
            }

            var pathData = pathMap.getScaledPath('DATA_OBJECT_PATH', {
                xScaleFactor: 1,
                yScaleFactor: 1,
                containerWidth: element.width,
                containerHeight: element.height,
                position: {
                    mx: 0.474,
                    my: 0.296
                }
            });

            var elementObject = drawPath(parentGfx, pathData, {fill: 'white'});

            var semantic = getSemantic(element);

            renderEmbeddedLabel(parentGfx, element, 'center-middle');

            return elementObject;
        },
        "privacy:PersonalData": function(parent, element) {
            const data = renderer("privacy:DocumentObject")(parent, element);

            createCustomIcon(Icons.personalPng, parent);

            return data;
        },
        "privacy:SpecialPersonalData": function(parent, element) {
            const data = renderer("privacy:DocumentObject")(parent, element);

            createCustomIcon(Icons.specialPng, parent);

            return data;
        },
        "privacy:Database": function(parentGfx, element) {
            if (element.width < 50) {
                element.width = 50;
            }
            if (element.height < 75) {
                element.height = 75;
            }

            var pathData = pathMap.getScaledPath('DATA_STORE', {
                xScaleFactor: 1,
                yScaleFactor: 1,
                containerWidth: element.width,
                containerHeight: element.height,
                position: {
                    mx: 0,
                    my: 0.1
                }
            });

            return drawPath(parentGfx, pathData, {fill: 'white'});
        },
        "privacy:AbstractContainerElement": function(parent, element, attrs) {
            if (element.width < 20) {
                element.width = 20;
            }
            if (element.height < 20) {
                element.height = 20;
            }

            var elementObject = drawRectWithHeader(parent, element.width, element.height, 0, attrs);

            renderEmbeddedLabel(parent, element, "center-middle", {
                box: assign(getHeaderSize(element), {
                    x: element.x,
                    y: element.y
                })
            });

            return elementObject;
        },
        'privacy:DataCategory': function(parent, element) {
            return renderer('privacy:AbstractContainerElement')(parent, element, {
                header: {
                    fill: '#fff',
                    stroke: '#000'
                }, body: {fill: '#fff', stroke: '#000'}
            });
        },
        "privacy:DataSubject": function(parent, element) {
            const url = Icons.subjectSvg;

            if(element.businessObject.name) {
                renderImageLabel(parent, element);
            }

            return drawImage(parent, element, 0, url);
        },
        "privacy:DataSource": function(parent, element) {
            const url = Icons.sourceSvg;

            if(element.businessObject.name) {
                renderImageLabel(parent, element);
            }

            return drawImage(parent, element, 0, url);
        },
        "privacy:DataConnection": function(parent, element) {
            var attrs = computeStyle(attrs, {
                stroke: '#cbcaca',
                strokeWidth: 2,
                strokeDasharray: "3, 3"
            });

            return svgAppend(parent, createLine(element.waypoints, attrs));
        },
        "privacy:ThirdPartyConnection": function(parent, element) {
            var attrs = computeStyle(attrs, {
                stroke: "#cc33cc",
                strokeWidth: 2,
                strokeDasharray: "10, 12",
                strokeLinecap: "round",
                strokeLinejoin: "round",
            });

            return svgAppend(parent, createLine(element.waypoints, attrs));
        },
        'ParallelMarker': function (parentGfx, element, position) {
            const markerPath = pathMap.getScaledPath('MARKER_PARALLEL', {
                xScaleFactor: 1,
                yScaleFactor: 1,
                containerWidth: element.width,
                containerHeight: element.height,
                position: {
                    mx: ((element.width / 2 + position.parallel) / element.width),
                    my: (element.height - 20) / element.height
                }
            });

            drawMarker('parallel', parentGfx, markerPath);
        },
        'SequentialMarker': function (parentGfx, element, position) {
            var markerPath = pathMap.getScaledPath('MARKER_SEQUENTIAL', {
                xScaleFactor: 1,
                yScaleFactor: 1,
                containerWidth: element.width,
                containerHeight: element.height,
                position: {
                    mx: ((element.width / 2 + position.seq) / element.width),
                    my: (element.height - 19) / element.height
                }
            });

            drawMarker('sequential', parentGfx, markerPath);
        },
        'LoopMarker': function (parentGfx, element, position) {
            var markerPath = pathMap.getScaledPath('MARKER_LOOP', {
                xScaleFactor: 1,
                yScaleFactor: 1,
                containerWidth: element.width,
                containerHeight: element.height,
                position: {
                    mx: ((element.width / 2 + position.loop) / element.width),
                    my: (element.height - 7) / element.height
                }
            });

            drawMarker('loop', parentGfx, markerPath, {
                strokeWidth: 1,
                fill: 'none',
                strokeLinecap: 'round',
                strokeMiterlimit: 0.5
            });
        }
    };

    function createCustomIcon(icon, parent, options) {
        options = options || {};

        const shapeGfx = svgCreate('image', assign({
            x: 2,
            y: 3,
            width: 25,
            height: 25,
            href: icon
        }, options));

        svgAppend(parent, shapeGfx);

        return shapeGfx;
    }

    function getHeaderSize(element) {
        return {width: element.width - 50, height: 25}
    }

    function drawRectWithHeader(parentGfx, width, height, r, attrs) {
        var size = getHeaderSize({width: width, height: height});
        var headerRect = drawRect(parentGfx, size.width, size.height, 0, 0, attrs.header);

        var rect = svgCreate('rect');
        svgAttr(rect, {
            x: 0,
            y: 25,
            width: width,
            height: height - 25
        });
        svgAttr(rect, attrs.body);

        svgAppend(parentGfx, rect);

        return headerRect;
    }

    function drawPath(parentGfx, d, attrs) {

        attrs = computeStyle(attrs, ['no-fill'], {
            strokeWidth: 2,
            stroke: 'black'
        });

        var path = svgCreate('path');
        svgAttr(path, {d: d});
        svgAttr(path, attrs);

        svgAppend(parentGfx, path);

        return path;
    }

    function drawRect(parentGfx, width, height, r, offset, attrs) {

        if (isObject(offset)) {
            attrs = offset;
            offset = 0;
        }

        offset = offset || 0;

        attrs = computeStyle(attrs, {
            stroke: 'black',
            strokeWidth: 2,
            fill: 'white'
        });

        var rect = svgCreate('rect');
        svgAttr(rect, {
            x: offset,
            y: offset,
            width: width - offset * 2,
            height: height - offset * 2,
            rx: r,
            ry: r
        });
        svgAttr(rect, attrs);

        svgAppend(parentGfx, rect);

        return rect;
    }

    function drawImage(parent, element, offset, url) {
        const imageGfx = svgCreate("image", {
            x: 0,
            y: 0,
            width: element.width,
            height: element.height,
            href: url
        });

        svgAppend(parent, imageGfx);

        return imageGfx;
    }

    function drawMarker(type, parentGfx, path, attrs) {
        return drawPath(parentGfx, path, assign({'data-marker': type}, attrs));
    }

    function renderer(type) {
        return handlers[type];
    }

    function attachTaskMarkers(parentGfx, element, taskMarkers) {
        const obj = getSemantic(element);

        const position = {
            seq: -3,
            parallel: -6,
            compensation: -27,
            loop: 0,
            adhoc: 10
        };

        forEach(taskMarkers, function(marker) {
            renderer(marker)(parentGfx, element, position);
        });

        const loopCharacteristics = obj.loopCharacteristics,
            isSequential = loopCharacteristics && loopCharacteristics.isSequential;

        if (loopCharacteristics) {

            if (isSequential === undefined) {
                renderer('LoopMarker')(parentGfx, element, position);
            }

            if (isSequential === false) {
                renderer('ParallelMarker')(parentGfx, element, position);
            }

            if (isSequential === true) {
                renderer('SequentialMarker')(parentGfx, element, position);
            }
        }
    }

    function renderLabel(parentGfx, label, options) {
        options = assign({
            size: {
                width: 100
            }
        }, options);

        const text = textRenderer.createText(label || '', options);

        svgClasses(text).add('djs-label');

        svgAppend(parentGfx, text);

        return text;
    }

    function renderEmbeddedLabel(parentGfx, element, align, attr) {
        attr = assign({box: element, align: align, padding: 5},
            attr);

        var semantic = getSemantic(element);
        return renderLabel(parentGfx, semantic.name, attr);
    }

    function renderExternalLabel(parentGfx, element) {
        var semantic = getSemantic(element);
        var box = {
            width: 90,
            height: 30,
            x: element.width / 2 + element.x,
            y: element.height / 2 + element.y
        };

        return renderLabel(parentGfx, semantic.name, {box: box, style: {fontSize: '11px'}, align: 'center-bottom'});
    }

    // hacky way to render Label on image
    // https://github.com/bpmn-io/bpmn-js-example-custom-elements/issues/3
    // https://github.com/rajgoel/bpmn-js-resources/blob/master/app/modules/ResourceRenderer.js
    function renderImageLabel(parent, element) {
        const lines = element.businessObject.name.trim().split('\n');
        const textArea = svgCreate('text');
        let text = '';
        const fontsize = 11;
        const width = element.width;
        const height = element.height;
        for (let i = 0; i < lines.length; ++i) {
            const posY = height + (i+1.5)*fontsize;
            text += '<tspan x="' + width/2 + '" y="' + posY + '">' + lines[i] + '</tspan>';
        }
        innerSVG(textArea,text);
        svgAttr(textArea, {
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: fontsize,
            textAnchor: 'middle',
            width: width,
            x: width,
            y: 0
        });
        svgAppend(parent, textArea);
    }

    this.canRender = function(element) {
        return this.handlers[element.type] != undefined;
    };

    this.drawShape = function (parent, element) {

        if (this.canRender(element)) {
            const handler = this.handlers[element.type];

            if (handler instanceof Function) {
                return handler(parent, element);
            }
        }

        return false;
    };

    this.drawConnection = function (parent, element) {
        if (isAny(element, getConnectionsAbleToDraw())) {
            return this.drawShape(parent, element);
        }
    };

    function getConnectionsAbleToDraw() {
        return ["privacy:DataConnection", "privacy:ThirdPartyConnection"];
    }
}

inherits(CustomRenderer, BaseRenderer);

CustomRenderer.$inject = [ 'eventBus', 'styles', 'pathMap', 'modeling', 'textRenderer' ];



// helpers //

function getSemantic(element) {
    return element.businessObject;
}
