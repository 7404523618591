import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";

import {
    getValueHelper,
    setValueHelper
} from "../utilities/PropertyHelpers";

/**
 * properties to hold content for reason of processing
 * only available for process or participant
 * models the properties as ExtensionElements
 */
export default function(group, element, moddle, type) {
    if (isAny(element, ['bpmn:Process', "bpmn:Participant"])) {

        const setValue = function(businessObject, property) {
            return setValueHelper(businessObject, type, property, moddle);
        };

        const getValue = function (businessObject, property) {
            return getValueHelper(businessObject, type, property);
        };

        group.entries.push(entryFactory.checkbox({
                id: 'pia',
                label: 'PIA needed?',
                modelProperty: 'pia',
            set: setValue(getBusinessObject(element), "pia"),
            get: getValue(getBusinessObject(element), "pia"),
        }));
    }
}
