import {
    assign
} from 'min-dash';

import Icons from "../../icons";

/**
 * This class holds the elements you can create with the palette
 * each element is defined and displayed and the actions to create the element on the canvas is triggered
 */
export default function CustomPalette(palette, create, elementFactory, spaceTool, lassoTool, handTool, globalConnect) {

    this._create = create;
    this._elementFactory = elementFactory;
    this._spaceTool = spaceTool;
    this._lassoTool = lassoTool;
    this._handTool = handTool;
    this._globalConnect = globalConnect;

    palette.registerProvider(this);
}

CustomPalette.$inject = [
    'palette',
    'create',
    'elementFactory',
    'spaceTool',
    'lassoTool',
    'handTool',
    'globalConnect'
];


CustomPalette.prototype.getPaletteEntries = function(element) {

    const actions  = {},
        create = this._create,
        elementFactory = this._elementFactory,
        spaceTool = this._spaceTool,
        lassoTool = this._lassoTool,
        handTool = this._handTool,
        globalConnect = this._globalConnect;


    function createAction(type, group, className, title, options) {

        function createListener(event) {
            const shape = elementFactory.createShape(assign({ type: type }, options));

            if (options) {
                shape.businessObject.di.isExpanded = options.isExpanded;
            }

            create.start(event, shape);
        }

        const shortType = type.replace(/^bpmn:/, '');

        return {
            group: group,
            className: className,
            title: title || 'Create ' + shortType,
            action: {
                dragstart: createListener,
                click: createListener
            }
        };
    }

    function createActionWithImage(type, group, imageUrl, title, options) {
        function createListener(event) {
            const shape = elementFactory.createShape(assign({type: type}, options));

            if (options) {
                shape.businessObject.di.isExpanded = options.isExpanded;
            }

            create.start(event, shape);
        }

        return {
            group: group,
            title: title,
            imageUrl: imageUrl,
            action: {
                dragstart: createListener,
                click: createListener
            }
        }

    }

    function createParticipant(event, collapsed) {
        create.start(event, elementFactory.createParticipantShape(collapsed));
    }

    assign(actions, {
        'hand-tool': {
            group: 'tools',
            className: 'bpmn-icon-hand-tool',
            title: 'Activate the hand tool',
            action: {
                click: function(event) {
                    handTool.activateHand(event);
                }
            }
        },
        'lasso-tool': {
            group: 'tools',
            className: 'bpmn-icon-lasso-tool',
            title: 'Activate the lasso tool',
            action: {
                click: function(event) {
                    lassoTool.activateSelection(event);
                }
            }
        },
        'space-tool': {
            group: 'tools',
            className: 'bpmn-icon-space-tool',
            title: 'Activate the create/remove space tool',
            action: {
                click: function(event) {
                    spaceTool.activateSelection(event);
                }
            }
        },
        'global-connect-tool': {
            group: 'tools',
            className: 'bpmn-icon-connection-multi',
            title: 'Activate the global connect tool',
            action: {
                click: function(event) {
                    globalConnect.toggle(event);
                }
            }
        },
        'tool-separator': {
            group: 'tools',
            separator: true
        },
        'create.start-event': createAction(
            'bpmn:StartEvent', 'event', 'bpmn-icon-start-event-none',
            'Create StartEvent'
        ),
        'create.intermediate-event': createAction(
            'bpmn:IntermediateThrowEvent', 'event', 'bpmn-icon-intermediate-event-none',
            'Create Intermediate/Boundary Event'
        ),
        'create.end-event': createAction(
            'bpmn:EndEvent', 'event', 'bpmn-icon-end-event-none',
            'Create EndEvent'
        ),
        'create.exclusive-gateway': createAction(
            'bpmn:ExclusiveGateway', 'gateway', 'bpmn-icon-gateway-none',
            'Create Gateway'
        ),
        'create.task': createAction(
            'bpmn:Task', 'activity', 'bpmn-icon-task',
            'Create Task'
        ),
        'create.participant-expanded': {
            group: 'collaboration',
            className: 'bpmn-icon-participant',
            title: 'Create Pool/Participant',
            action: {
                dragstart: createParticipant,
                click: createParticipant
            }
        },
        'privacy-separator': {
            group: 'privacy',
            separator: true
        },
        'create.personal-data': createActionWithImage(
            'privacy:PersonalData', 'privacy', Icons.personalDataPng,
            'Create Personal Data'
        ),
        'create.database': createAction(
            'privacy:Database', 'privacy', 'bpmn-icon-data-store',
            'Create Database'
        ),
        'create.category': createActionWithImage(
            'privacy:DataCategory', 'privacy', Icons.dataCategoryPng,
            'Create Data Category'
        ),
        'create.data-subject': createActionWithImage("privacy:DataSubject", "privacy", Icons.subjectPng,
            "Create Data Subject"
        ),
        'create.data-source': createActionWithImage("privacy:DataSource", "privacy", Icons.sourcePng,
            "Create Data Source"
        )
    });

    return actions;
};
