import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";

import {
    getEnumValues,
    getValueHelper,
    setValueHelper
} from "../utilities/PropertyHelpers";

/**
 * properties to hold all information for the documents needed to cover legal basis
 * selectBox is based on enum of privacy:DocumentType
 * only available for process or participant
 * models the properties as ExtensionElements
 */
export default function(group, element, moddle, type) {
    if (isAny(element, ['bpmn:Process', "bpmn:Participant"])) {

        const setValue = function(businessObject, property) {
            return setValueHelper(businessObject, type, property, moddle);
        };

        const getValue = function (businessObject, property) {
            return getValueHelper(businessObject, type, property);
        };

        group.entries.push(entryFactory.textBox({
            id: 'path',
            label: 'Path to Document',
            modelProperty: 'path',
            set: setValue(getBusinessObject(element), "path"),
            get: getValue(getBusinessObject(element), "path")
        }));

        group.entries.push(entryFactory.selectBox({
            id: 'documentType',
            description: 'Type of needed document',
            label: 'Document Type',
            modelProperty: 'documentType',
            selectOptions: getEnumValues('privacy:DocumentType'),
            set: setValue(getBusinessObject(element), "documentType"),
            get: getValue(getBusinessObject(element), "documentType")
        }))
    }
}
