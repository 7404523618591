/**
 * this holds the replacement options when replacing with the context pad
 * it also insures that only the needed elements are shown
 */

// replacement for task type
module.exports.TASK = [
    {
        label: 'Task',
        actionName: 'replace-with-task',
        className: 'bpmn-icon-task',
        target: {
            type: 'bpmn:Task'
        }
    },
    {
        label: 'Send Task',
        actionName: 'replace-with-send-task',
        className: 'bpmn-icon-send',
        target: {
            type: 'bpmn:SendTask'
        }
    },
    {
        label: 'Receive Task',
        actionName: 'replace-with-receive-task',
        className: 'bpmn-icon-receive',
        target: {
            type: 'bpmn:ReceiveTask'
        }
    },
    // Privacy extension
    {
        label: 'Data processing',
        actionName: 'replace-with-data-processing',
        className: 'privacy-icon-processing',
        target: {
            type: 'privacy:DataProcessing'
        }
    },
    {
        label: 'Automatic Processing',
        actionName: 'replace-with-automatic-processing',
        className: 'privacy-icon-automatic',
        target: {
            type: 'privacy:AutomaticProcessing'
        }
    },
    {
        label: 'Processor',
        actionName: 'replace-with-processor',
        className: 'privacy-icon-processor',
        target: {
            type: 'privacy:Processor'
        }
    },
    {
        label: 'Joint Controller',
        actionName: 'replace-with-joint-controller',
        className: 'privacy-icon-joint-controller',
        target: {
            type: 'privacy:JointController'
        }
    },
    {
        label: 'Instructionless Processing',
        actionName: 'replace-with-instructionless-processing',
        className: 'privacy-icon-instructionless-processing',
        target: {
            type: 'privacy:InstructionlessProcessing'
        }
    }
];

// replacement for gateways
module.exports.GATEWAY = [
    {
        label: 'Exclusive Gateway',
        actionName: 'replace-with-exclusive-gateway',
        className: 'bpmn-icon-gateway-xor',
        target: {
            type: 'bpmn:ExclusiveGateway'
        }
    },
    {
        label: 'Inclusive Gateway',
        actionName: 'replace-with-inclusive-gateway',
        className: 'bpmn-icon-gateway-or',
        target: {
            type: 'bpmn:InclusiveGateway'
        }
    }
];

// replacement for PersonalData
module.exports.DATA = [
    {
        label: 'Personal Data',
        actionName: 'replace-with-personal-data',
        className: 'privacy-icon-data-down',
        target: {
            type: 'privacy:PersonalData'
        }
    },
    {
        label: 'Special Personal Data',
        actionName: 'replace-with-special-personal-data',
        className: 'privacy-icon-data-up',
        target: {
            type: 'privacy:SpecialPersonalData'
        }
    }
];
