import idProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/IdProps"
import nameProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/NameProps"
import processProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/ProcessProps"
import linkProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/LinkProps"
import eventProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/EventProps"
import documentationProps from "bpmn-js-properties-panel/lib/provider/bpmn/parts/DocumentationProps"

import reasonProps from "./properties/ReasonProps";
import legalProps from "./properties/LegalProps";
import documentProps from "./properties/DocumentProps";
import retentionProps from "./properties/RetentionProps";
import processingProps from "./properties/ProcessingProps";
import measuresProps from "./properties/MeasuresProps";
import piaProps from "./properties/PiaProps";
import responsibleProps from "./properties/ResponsibleProps";

import inherits from "inherits";

import PropertiesActivator from "bpmn-js-properties-panel/lib/PropertiesActivator";

/**
 * This module takes care of the properties panel shown on the right side of the canvas.
 * All tabs with their properties are defined here.
 */
function createGeneralTabGroup(element, bpmnFactory, elementRegistry, translate) {
    const generalGroup = {
        id: 'general',
        label: 'General',
        entries: []
    };

    idProps(generalGroup, element, translate);
    nameProps(generalGroup, element, bpmnFactory, elementRegistry, translate);
    processProps(generalGroup, element, translate);

    const detailsGroup = {
        id: 'details',
        label: 'Details',
        entries: []
    };

    linkProps(detailsGroup, element, translate);
    eventProps(detailsGroup, element, bpmnFactory, elementRegistry, translate);

    const documentationGroup = {
        id: 'documentation',
        label: 'Documentation',
        entries: []
    };

    documentationProps(documentationGroup, element, bpmnFactory, translate);

    return [
        generalGroup,
        detailsGroup,
        documentationGroup
    ];
}

function createProcessTabGroups(element, elementRegistry, moddle) {
    const reasonGroup = {
        id: 'reason-group',
        label: 'Reason',
        entries: []
    };

    reasonProps(reasonGroup, element, moddle, "privacy:Reason");

    const legalGroup = {
        id: 'legal-group',
        label: 'Legal basis',
        entries: []
    };

    legalProps(legalGroup, element, moddle, "privacy:LegalBasis");
    documentProps(legalGroup, element, moddle, "privacy:Document");

    const attributesGroup = {
        id: 'attributes-group',
        label: 'Settings',
        entries: []
    };

    measuresProps(attributesGroup, element, moddle, "privacy:Measures");
    piaProps(attributesGroup, element, moddle, "privacy:Pia");
    responsibleProps(attributesGroup, element, moddle, "privacy:Responsible");

    return [
        reasonGroup,
        legalGroup,
        attributesGroup
    ]
}

function createPersonalDataTabGroups(element, elementRegistry, moddle) {
    const retentionGroup = {
        id: 'date-group',
        label: 'Retention Duration',
        entries: []
    };

    retentionProps(retentionGroup, element, moddle, "privacy:Retention");

    return [
        retentionGroup
    ]
}

function createThirdPartyTabGroups(element) {
    const processingContractGroup = {
        id: 'processing-contract-group',
        label: 'Path to Processing Contract',
        entries: []
    };

    processingProps(processingContractGroup, element);

    return [
        processingContractGroup
    ]
}

export default function CustomPropertiesProvider(eventBus, bpmnFactory, elementRegistry, translate, moddle) {
    PropertiesActivator.call(this, eventBus);

    this.getTabs = function(element) {

        const generalTab = {
            id: 'general',
            label: 'General',
            groups: createGeneralTabGroup(element, bpmnFactory, elementRegistry, translate)
        };

        const privacyTab = {
            id: 'privacy',
            label: 'Privacy',
            groups: createProcessTabGroups(element, elementRegistry, moddle)
        };

        const personalDataTab = {
            id: 'personal-data',
            label: 'Privacy',
            groups: createPersonalDataTabGroups(element, elementRegistry, moddle)
        };

        const thirdPartyTab = {
            id: 'third-party',
            label: 'Privacy',
            groups: createThirdPartyTabGroups(element)
        };

        return [
            generalTab,
            privacyTab,
            personalDataTab,
            thirdPartyTab
        ];
    };
}

inherits(CustomPropertiesProvider, PropertiesActivator);

CustomPropertiesProvider.$inject = [ 'eventBus', 'bpmnFactory', 'elementRegistry', 'translate', 'moddle' ];
